/* Date Picker disable days w/o slots */
.rmdp-day:has(.noslots) {
    background-color: #eaebee;
}

div.noslots {
    background-color: #eaebee;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}