.review-container {
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
  border-bottom: 1px solid #eaeff5;
}
.owner-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
@media (max-width: 786px) {
  .review-container {
    padding: 2rem 0.5rem;
  }
  .owner-image {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }
}
