.formInput-container {
  width: 75vw;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px rgb(71 85 95 / 8%);
  position: absolute;
  bottom: -32vh;
  padding: 2.3rem 0.8rem;
}
#form-input {
  outline: none;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background-color: #fbfdff;
}
#form-input:hover,
#form-input:focus {
  border: 1px solid #f7628f;
  transition: ease-in-out 0.15s;
}

.cards-container {
  margin-top: 130px;
}

.btn-undo {
  min-width: fit-content;
  margin-top: -10px;
}

@media (max-width: 900px) {
  .formInput-container {
    width: 90vw;
    bottom: -35vh;
    padding: 1.5rem 0.8rem;
  }
  .button {
    width: 100%;
    padding: '12px 50px';
  }
}

@media (max-height: 900px) and (max-width: 900px) {
  .formInput-container {
    width: 90vw;
    bottom: -40vh;
    padding: 1.5rem 0.8rem;
  }
  .button {
    width: 100%;
    padding: '12px 50px';
  }

  .cards-container {
    margin-top: 150px;
  }
}

@media (max-height: 800px) and (max-width: 900px) {
  .formInput-container {
    width: 90vw;
    bottom: -55vh;
    padding: 1.5rem 0.8rem;
  }
  .button {
    width: 100%;
    padding: '12px 50px';
  }

  .cards-container {
    margin-top: 200px;
  }
}

@media (max-height: 680px) {
  .formInput-container {
    width: 90vw;
    bottom: -80vh;
    padding: 1.5rem 0.8rem;
  }
  .button {
    width: 100%;
    padding: '12px 50px';
    margin-top: 5px;
  }

  .cards-container {
    margin-top: 300px;
  }
}

.maxwidth {
  width: 75vw;
}

@media (max-width: 900px) {
  .maxwidth {
    width: 95vw;
  }
}
